import React from "react";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Cert from "./Cert/MainApp";
// import bg from "./../assets/onlinecertbg.jpg";
// import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "30px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: "20px",
    },
  },
  bg: {
    // backgroundImage: `url(${bg})`,
    background: "rgba(0,0,0)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

export default function Onlinecerts() {
  const classes = useStyles();
  return (
    <div className={classes.bg}>  
        <Typography align="center" className={classes.pagetitle}>
          Search for Certificate of Conformance
        </Typography>
        <div style={{ minHeight: "60vh" ,display:'flex', alignItems:'center' }}>
        <Container
          maxWidth="lg"
          className="animate__animated animate__fadeIn ">
          <Cert />
        </Container>
        </div>
    </div>
  );
}

// pagetitle:{
//     color: '#231f20',
//     fontFamily:'Poppins',
//     paddingTop:'30px',
//     fontSize: '42px'
//   },
//   pagesubtitle:{
//    paddingBottom: '40px', 
//    fontFamily: 'Poppins'
//   },