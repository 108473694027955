import React from 'react';
import { makeStyles } from '@mui/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import {animateScroll as scroll} from 'react-scroll';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    scroll.scrollToTop();
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} 
      className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default function BackToTop(props) {
  return (
    <React.Fragment>     
      <ScrollTop {...props}>
        <Fab size="small" style={{color: '#e4e4e4'}}>
          <KeyboardArrowUpIcon style={{color: '#02192D'}}/>
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
