export const partArr = [
  {
    "value": "LFA40102",
    "label": "LFA40102 1/4\"-20 x .56\", 550 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40104",
    "label": "LFA40104 5/16\"-18 x .56\", 800 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40106",
    "label": "LFA40106 5/16\"-24 x .56\", 800 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40108",
    "label": "LFA40108 5/16\"-18 x 1.06\", 800 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40110",
    "label": "LFA40110 3/8\"-16 x .56\", 1000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40112",
    "label": "LFA40112 3/8\"-24 x .56\", 1000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40114",
    "label": "LFA40114 3/8\"-16 x 1.06\", 1000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40116",
    "label": "LFA40116 1/2\"-13 x 1.06\", 2500 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40118",
    "label": "LFA40118 1/2\"-20 x 1.06\", 2500 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40120",
    "label": "LFA40120 1/2\"-13 x .75\", 2500 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40122",
    "label": "LFA40122 1/2\"-13 x 1.00\", 2500 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40124",
    "label": "LFA40124 1/2\"-13 x 1.25\", 2500 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40126",
    "label": "LFA40126 5/8\"-11 x .75\", 4000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40128",
    "label": "LFA40128 5/8\"-11 x 1.00\", 4000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40130",
    "label": "LFA40130 5/8\"-11 x 1.25\", 4000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40132",
    "label": "LFA40132 3/4\"-10 x 1.00\", 5000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40134",
    "label": "LFA40134 3/4\"-10 x 1.50\", 5000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40136",
    "label": "LFA40136 3/4\"-10 x .95\", 7000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40138",
    "label": "LFA40138 3/4\"-10 x 1.45\", 7000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40140",
    "label": "LFA40140 7/8\"-9 x 1.20\", 8000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40142",
    "label": "LFA40142 1\"-8 x 1.45\", 10000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40144",
    "label": "LFA40144 1\"-8 x 2.45\", 10000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40146",
    "label": "LFA40146 1-1/4\"-7 x 2.63\", 15000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40148",
    "label": "LFA40148 1-3/8\"-6 x 2.63\", 20000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40150",
    "label": "LFA40150 1-1/2\"-6 x 2.63\", 24000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40152",
    "label": "LFA40152 2\"-4.5 x 2.96\", 30000 lb WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40202",
    "label": "LFA40202 M8 x 16mm, 400 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40204",
    "label": "LFA40204 M10 x 16mm, 450 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40206",
    "label": "LFA40206 M12 x 25mm, 1050 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40208",
    "label": "LFA40208 M14 x 25mm, 1500 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40210",
    "label": "LFA40210 M16 x 25mm, 1900 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40212",
    "label": "LFA40212 M20 x 25mm, 2200 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40214",
    "label": "LFA40214 M24 x 38mm, 4200 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40216",
    "label": "LFA40216 M30 x 48mm, 4500 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40218",
    "label": "LFA40218 M30 x 67mm, 7000 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40220",
    "label": "LFA40220 M36 x 67mm, 11000 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40222",
    "label": "LFA40222 M42 x 80mm, 12500 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40224",
    "label": "LFA40224 M48 x 80mm, 13500 kg WLL, LFT Swivel Hoist Ring"
  },
  {
    "value": "LFA40226",
    "label": "LFA40226 M56 x 88mm, 13500 kg WLL, LFT Swivel Hoist Ring"
  }
 ]