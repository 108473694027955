import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Topnav from '../components/Topnav'
import Scroll from './Scroll'


const useStyles = makeStyles(theme => ({
  root: {    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },    
  },
  tree:{
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  list : {
    width : 200,
    fontFamily: 'Poppins'
  },  
  sideBarIcon : {
    color : "white",
    cursor : "pointer",
  },
  link:
    { 
      fontFamily: 'Poppins',
      fontSize: '22px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: '#ebebe6',
      display:'flex',
      justifyContent: 'space-evenly',
      '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
     },   
    },
    reslink:
    {
        textDecoration: 'none',
        color: '#213C54',
    },
     reslink2:
    {
        textDecoration: 'none',
        color: '#213C54',
       fontSize:'15px'
    },
    
}));

function ElevateAppBar() {
  const classes = useStyles();
 
  return (    
    <React.Fragment>
      <Topnav/>
      <AppBar className={classes.root} position="sticky" 
      style={{ backgroundColor: '#02192D'}} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>        
        <Toolbar style={{minHeight: '50px'}}>
        <Typography  align="center"> 
        <Scroll/>       
          </Typography>          
          <Typography variant = "subheading"color="inherit" style={{flexGrow:1}}> 
          <Link to="/hoistrings" className={classes.link}>Hoist Rings
          </Link></Typography>
          {/* <Typography variant = "subheading" color="inherit" style={{flexGrow:1}}> 
          <Link to="/technicalresources" className={classes.link}>Technical Resources</Link></Typography> */}
          <Typography variant = "subheading"color="inherit" style={{flexGrow:1}}> 
          <Link to="/onlinecerts" className={classes.link}>Online Certs
          </Link></Typography> 
      </Toolbar>
      </AppBar>      
    </React.Fragment>
  );
};


export default ElevateAppBar;