import React from 'react'
import {Grid, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from './Header'
import LFTimg from './../assets/LFT-swivel-hoist-rings-full-360-view.webp'

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px'
   },
   pagesubtitle:{
    paddingBottom: '40px', 
    fontFamily: 'Poppins'
   },
 }));
 
 export default function Wirerope() {
    const classes = useStyles();
    return (
        <div>             
        <Container maxWidth="md" component="main" className={classes.heroContent}>            
        <Typography  align="center"className={classes.pagetitle} style={{color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px'}} gutterBottom>
        Hoist Rings
       </Typography>   
       <Grid container justify="center" alignItems="center"style={{justifyContent:"center", alignItems: "center"}} >
       <img src={LFTimg} alt="" style={{ height: '400px', margin: '10px'}}/> 
           </Grid>   
           <Typography className={classes.pagesubtitle} align="center"  style={{ paddingBottom: '40px', 
    fontFamily: 'Poppins'}}>
           LFT Forged Bail Hoist Ring Threaded Stud Specifications: 1/4 inch diameter, 20 threads per inch, 0.56 inches long. Working Load Limit: 550 pounds.
           <br></br><br></br>   <strong>Forged Bail Hoist Ring Features</strong><br></br> </Typography>     

<Typography  align="center" className={classes.pagecontent} style={{ paddingBottom: '40px', 
    fontFamily: 'Poppins'}}>
<ul>
<li>Features a large attachment point for greater connection versatility.</li>
<li>5:1 Design Factor and 100% load rated throughout 360° swivel and 180° pivot.</li>
<li>Alloy steel forged bails 200% load tested and Non-Destructive tested via the Resonant Acoustic Method per ASTM E2001</li>
<li>Alloy Steel and Stainless-Steel machined components are Non-Destructive tested via the Resonant Acoustic Method per ASTM E2001</li>
<li>Raised lettering on forged bail showing heat code and Manufacturer for traceability.</li>
<li>Meets ANSI/ASME B30.26</li>
<li>Permanently marked with an individualized serial number, load rating, torque specification, and part number.</li>
<li>Geomet coated for superior corrosion resistance and powder coated for a high-visibility durable finish.</li>
</ul>
           </Typography>      
        </Container>   
        {/* <div style={{backgroundColor: '#d6d9dd'}}><Header/> </div>   */}
        </div>
    )
}