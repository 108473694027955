import './App.css';
import React from "react";
import {  Routes, Route} from "react-router-dom";
import Privacy from './components/Privacy'
import Termsofuse from './components/Termsofuse'
import Contactform from './components/Contactform'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from './components/Appbar'
import  Box   from '@mui/material/Box';
import Drawer from './components/Drawer'
import Footer from './components/Footer';
import Home from './components/Home';
import Technical from './components/TechnicalResources';
import Hoist from './components/HoistRings';
import Online from './components/OnlineCerts';
import AddCertificate from './components/Cert/AddCertificate'
import AllCerts from './components/Cert/AllCerts'

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

 function App() {
  return (
    <div>
       {/* <ScrollToTop />  */}
       <Navbar/>   
      <Box display={{ md: 'block', lg: 'none' }} ><br/><br/><Drawer/></Box> 

       <Routes>
        <Route exact path="/" element={<Home/>}></Route>
        <Route path="/contactform" element={<Contactform/>}></Route>
        <Route path="/privacypolicy" element={<Privacy/>}></Route>
        <Route path="/termsofuse" element={<Termsofuse/>}></Route>
        <Route path="/technicalresources" element={<Technical/>}></Route>
        <Route path="/hoistrings" element={<Hoist/>}></Route>
        <Route path="/onlinecerts" element={<Online/>}></Route>
        <Route path="/operatorAddCertificate" element={<AddCertificate/>}></Route>
        <Route path="/operatorAllCerts" element={<AllCerts/>} />
      </Routes>
      <Footer/>
    </div>
  );
}


export default App;
