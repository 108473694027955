import React from 'react'
import {  Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px'
   },
   pagesubtitle:{
    paddingBottom: '40px', 
    fontFamily: 'Poppins',
    fontSize:'20px'
   },
 }));

export default function About() {
    const classes = useStyles();
    return (
        <div id="about" style={{backgroundColor: '#d6d9dd', paddingBottom: '50px'}}>    
            <Container maxWidth="md" component="main" >
                <Typography  align="center" className={classes.pagetitle} style={{color: '#231f20',
                    marginBottom: '40px',
                    marginTop: '40px',
                    fontFamily:'Poppins',
                    fontSize: '42px'}} gutterBottom>
                About Us
                </Typography>   
                <Typography  className={classes.pagesubtitle} style={{
                    paddingBottom: '40px', 
                    fontFamily: 'Poppins',
                    fontSize:'20px'}}>
                An innovatively designed, super strong, drop forged Hi-Viz bail is at the heart of this Made in USA line of swivel hoist rings. With most competitors having their devices black oxide coated, LFT swivel hoist rings bolts are Geomet coated and the remainder of components are powder coated for outstanding corrosion resistance.  
             </Typography>                        
             </Container>                
        </div>
    )
}
