import React, { useRef } from "react";
import { Grid } from "@mui/material";
// import XQ1 from "./../../assets/cert/XQ1.png";
// import XQ2 from "./../../assets/cert/XQ2.png";
import Mike from "./../../assets/mike.png";
import Logo from "./../../assets/logo.svg";
import Flag from "./../../assets/flag-icon.png";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import { makeStyles } from "@mui/styles";
import PrintIcon from "@mui/icons-material/Print";
import Box from "@mui/material/Box";
import { useReactToPrint } from 'react-to-print';


const CertDetail = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: 8.5in 10.5in; margin: 0 auto;} @media print { html, body { -webkit-print-color-adjust: exact; padding: 14px !important;} }'
  });

  const useStyles = makeStyles((theme) => ({
    mystyle: {
      padding: "7px",
      border: "4px solid black",
      fontSize: "18px",
      fontFamily: "Roboto",
      [theme.breakpoints.down("sm")]: {
        padding: "5px",
        border: "1px solid black",
        fontSize: "12px",
      },
    },
    padding: {
      padding: "50px",
      backgroundColor: "rgb(250,250,250,0.7)",
      background: "rgb(150,150,150)",
      [theme.breakpoints.down("sm")]: {
        padding: "12px",
      },
    },
    title: {
      fontSize: "32px",
      fontFamily: "Roboto",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    subtitle: {
      fontSize: "25px",
      fontFamily: "Roboto",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  }));

  function reload() {
    window.scroll(0, 0);
    window.location.reload(true);
  }

  const {
    serial,
    date,
    part,
    name,
    wll,
    fabricator,
    torque,
  } = props.cert;

  function handleClick() {
    document.getElementById("myP").style.display = "block";
    //document.getElementById("reset").style.display = "none";
    //document.getElementById('scroll').scrollIntoView();
  }
  function viewCert() {
    document.getElementById("prompt").style.display = "none";
  }
  const fileName = props.cert.Serial;

  const classes = useStyles();
  return (
    <div id="table" className="tho-table" style={{marginBottom: "30px", marginTop: "30px"}}>
      <div align="center" id="prompt" className={classes.padding}>
        <p className={classes.title}>LFT General Information</p>
        <p className={classes.subtitle}>
          Instructions: Click View Certificate to Access Printable Certificate
        </p>
        <p className="ie">
          Please use a different browser to Print/ Download a Certificate
        </p>
        <Box display={{ xs: "none", sm: "block" }}>
          <table
            style={{
              margin: "20px 0",
              textAlign: "center",
              borderCollapse: "collapse",
              backgroundColor: "white",
            }}
            className="animate__animated animate__zoomIn"
          >
            <tbody>
              <tr>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Serial #
                </td>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Date
                </td>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Part #
                </td>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Name
                </td>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  WLL
                </td>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Manufacturer
                </td>
              </tr>
              <tr>
                <td
                  className={classes.mystyle}
                  style={{ backgroundColor: "yellow" }}
                >
                  <strong>{serial}</strong>
                </td>
                <td className={classes.mystyle}>{date}</td>
                <td className={classes.mystyle}>{part}</td>
                <td className={classes.mystyle}>{name}</td>
                <td className={classes.mystyle}>{wll}</td>
                <td className={classes.mystyle}>LFT</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box display={{ xs: "block", sm: "none" }}>
          <table
            style={{
              margin: "20px 0",
              textAlign: "center",
              borderCollapse: "collapse",
              backgroundColor: "white",
            }}
            className="animate__animated animate__fadeIn"
          >
            <tbody>
              <tr>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Serial #
                </td>
                <td
                  className={classes.mystyle}
                  style={{ backgroundColor: "yellow" }}
                >
                  <strong>{serial}</strong>
                </td>
              </tr>

              <tr>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Date
                </td>
                <td className={classes.mystyle}>{date}</td>
              </tr>

              <tr>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Part #
                </td>
                <td className={classes.mystyle}>{part}</td>
              </tr>

              <tr>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Name
                </td>
                <td className={classes.mystyle}>{name}</td>
              </tr>

              <tr>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  WLL
                </td>
                <td className={classes.mystyle}>{wll}</td>
              </tr>
              <tr>
                <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                  Manufacturer
                </td>
                <td className={classes.mystyle}>LFT</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Grid container spacing={0}>
          <Grid item lg={6} md={12} sm={12} xs={12} className="end">
            <button
              className="thoinv-button animate__animated animate__zoomIn"
              onClick={() => {
                window.location.reload(true);
              }}
              style={{ margin: "10px" }}
            >
              Reset Search
            </button>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} className="start">
            <button
              className="tho-button animate__animated animate__zoomIn"
              onClick={() => {
                handleClick();
                setTimeout(() => {
                  viewCert();
                }, 20);
              }}
              style={{ margin: "10px" }}
            >
              View Certificate
            </button>
          </Grid>
        </Grid>
      </div>
      <div id="scroll"></div>
      <div
        id="myP"
        style={{ display: "none" }}
        align="center"
        className="animate__animated animate__fadeIn"
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={12} sm={12} xs={12} className="end">
            <button onClick={reload} className="thoinv-button">
              Reset Search
            </button>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} className="start">
            <button
              className="print-button"
              onClick={handlePrint}
            >
              <PrintIcon
                className="hide-ie"
                style={{ verticalAlign: "middle", paddingRight: "10px" }}
              />
              Print Certificate
            </button>
          </Grid>
        </Grid>

        <Certificate
        className="toPrint"
          ref={componentRef}
          s={serial}
          m="LFT"
          p={part}
          pd={name}
          v={wll}
          f={fabricator}
          d={date}
          t={torque}
        />
      </div>
    </div>
  );
};

export default CertDetail;

const titlestyle = {
  textAlign: "right",
  fontSize: "12px",
  paddingRight: "2px",
  marginTop: "2.5px",
  marginbottom: "2.5px",
  fontFamily: "Roboto",
};

const datastyle = {
  fontSize: "12px",
  textAlign: "left",
  marginTop: "2.5px",
  marginbottom: "2.5px",
  marginLeft: "3px",
  fontFamily: "Roboto",
};



const Certificate = React.forwardRef((props, ref) => {


  return (
    <div id={props.printableId} ref={ref}>
      <div align="center" className="certificate">
        <div
          style={{
            border: "5px solid black",
            padding: "10px",
            marginTop: "18px",
            backgroundColor: "white",
          }}
        >
           <br />
          <Grid container spacing={0}>
           
            <Grid item lg={6}>
              <img align="left" src={Logo} alt="LFT logo" id="the-logo" width="155px" />
            </Grid>
            <Grid item lg={6}>
              <p align="right" style={{ fontSize: "12px", textAlign: "center", marginLeft: "50px" }}>
                Address: 1000 Edwards Avenue, Suite C,
                <br /> New Orleans, LA, USA 70123
                <br />
                <a
                  href="https://www.lft.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  LFT360.com&nbsp;
                </a>
                1-888-682-8186
              </p>
            </Grid>
          </Grid>
          <br />
          <br />
          <p className="cer-maintitle"><strong>CERTIFICATE OF CONFORMANCE</strong></p>
          <br />
          <br/>
          <Grid container spacing={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Serial :</strong>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}>{props.s}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Manufacturer :</strong>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}>{props.m}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Part # :</strong>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}> {props.p}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Part Description : </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}>{props.pd}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}></p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>WLL : </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}>{props.v}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Torque : </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}>{props.t}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Design Factor : </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "0px" }}>
                    <p style={datastyle}>5:1</p>
                  </Grid>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </Grid>
            {/* middle fat container */}
          </Grid>
          <br />
          {/*main big container */}
          <Grid container spacing={3}>
            <Grid item lg={2} xs={3}>
              {/* <img src={XQ2} alt="XQ1" className="tho-smimg" /> */}
            </Grid>
            <Grid item lg={2} xs={3}>
              {/* <img src={XQ1} alt="XQ2" className="tho-smimg" /> */}
            </Grid>
            <Grid
              item
              lg={8}
              xs={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <p style={{ fontSize: "14px", fontFamily: "Roboto" }}>
                {/* <i>
                  <strong>
                    Learn more about XQ Quality Assurance at
                    <a
                      href="http://sldrigging.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "black", textAlign: "right" }}
                    >
                      XQassurance.com
                    </a>
                  </strong>
                </i> */}
              </p>
            </Grid>
          </Grid>
          <p
            style={{
              fontSize: "15px",
              textAlign: "left",
              fontFamily: "Roboto",
            }}
          >
            <strong>Statement: </strong>
            <br />
            This is to certify that this product has been fabricated, inspected,
            and rated according to the requirements of the following applicable
            organizations: OSHA, ASME, ASTM, & ANSI.
            <br />
            <br />
            NOTE: READ ALL INSTRUCTIONS BEFORE USE!, NEVER EXCEED THE RATED
            CAPACITY! INSPECT PRODUCT FREQUENTLY! AVOID SHOCK LOADS!
          </p>
          <br />
          <br />
          <br />
          <Grid container spacing={2}>
        
            <Grid item xs={3} style={{paddingTop: "17px", marginLeft: "18px"}}>
                  <img  src={Flag} alt="US Flag" />
                    <span style={{fontSize: "12px"}}>Made in USA</span>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={0}>
                <Grid container spacing={1} align="center">
                  <Grid item xs={4} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Assembly Technician:</strong>
                    </p>
                  </Grid>
                  <Grid item xs={8} style={{ padding: "0px" }}>
                    <p style={datastyle}>{props.f}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={4} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>QC Admin:</strong>
                    </p>
                  </Grid>
      
                  <Grid item xs={8} style={{ textAlign: "left" }}>
                    <img
                      src={Mike}
                      alt="sign"
                      style={{
                        height: "40px",
                        borderBottom: "1px solid black",
                        margin: "2px",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={4} style={{ padding: "0px" }}></Grid>
                  <Grid item xs={8} style={{ padding: "0px" }}>
                    <p
                      style={{
                        margin: "0 0 8px 8px",
                        fontSize: "13px",
                        textAlign: "left",
                      }}
                    >
                      Mike Hall
                    </p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} align="center">
                  <Grid item xs={4} style={{ padding: "0px" }}>
                    <p style={titlestyle}>
                      <strong>Date:</strong>
                    </p>
                  </Grid>
                  <Grid item xs={8} style={{ padding: "0px" }}>
                    <p style={datastyle}>{props.d} </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />

          <br/>
          <br/>
          <p style={{ fontSize: "14px", fontFamily: "Roboto" }}>
            LFT is a registered trademark and division of Sea-Land
            Distributors, LLC.
            </p>
          <br/>
 
        </div>
      </div>
    </div>
  );
})